<template>
  <v-app>
    <v-app-bar app color="white">
      <div class="d-flex align-center">
        <v-app-bar-nav-icon class="mr-3">
          <a href="/">
            <v-img
              height="35"
              contain
              :src="require('@/assets/common/logo.png')"
            ></v-img>
          </a>
        </v-app-bar-nav-icon>
        <v-toolbar-title>
          <v-toolbar-title>{{ $route.meta.pageName }}</v-toolbar-title>
        </v-toolbar-title>
      </div>
    </v-app-bar>

    <v-main>
      <v-container>
        <div>
          <h3>Điều khoản sử dụng dịch vụ SSC</h3>
          <div class="text-justify text-font-modify">
            <p>
              SSC.ECO (SSC) là một sản phẩm của CTCP Dịch Vụ Cung Ứng SSC phát
              triển từ năm 2018, SSC cung cấp End-to-End Solution trong Thiết
              kế, Quản lý, Tối ưu vận hành Đa kho &amp; Chuỗi cửa hàng quy mô
              công nghiệp.
            </p>
            <p>
              <b>1. MỤC ĐÍCH THU THẬP THÔNG TIN CÁ NHÂN:</b> <br />
              SSC thu thập những thông tin sau để cung cấp dịch vụ tốt hơn cho
              Đối tác:
            </p>
            <ul>
              <li>
                Thông tin Đối tác cung cấp cho SSC: SSC thu thập thông tin như
                địa chỉ liên hệ qua Email, Số điện thoại và khắc phục sự cố được
                cung cấp trong phản hồi của Đối tác. SSC sử dụng thông tin này
                để cung cấp hỗ trợ kỹ thuật tốt hơn và cải tiến sản phẩm của SSC
                trong tương lai.
              </li>
              <li>
                Thông tin SSC nhận được từ việc sử dụng dịch vụ và phần mềm của
                SSC, bao gồm nhưng không giới hạn như: tên hiển thị, dữ liệu từ
                Excel. Email, dữ liệu từ Website, các kênh TMĐT… có kết nối với
                với SSC.
              </li>
              <li>
                Thông tin đăng nhập bằng cách sử dụng dịch vụ và phần mềm của
                SSC, SSC có thể tự động thu thập và lưu trữ một số thông tin
                nhất định trên các máy chủ của SSC.
              </li>
            </ul>
            <br />
            <p>
              <b>2. PHẠM VI SỬ DỤNG THÔNG TIN:</b>
            </p>
            <ul>
              <li>Địa chỉ, số điện thoại, Email.</li>
              <li>Dữ liệu về hàng hóa, số lượng hàng hóa.</li>
            </ul>
            <br />
            <p>
              <b>3. THỜI GIAN LƯU TRỮ THÔNG TIN:</b>
            </p>
            <ul>
              <li>
                SSC lưu trữ các thông tin thu thập được từ các nguồn, thông tin
                đơn hàng trong thời gian không giới hạn.
              </li>
              <li>
                Nếu có phát sinh xóa dữ liệu chủ động từ SSC, SSC sẽ thông báo
                cho đối tác trước tối thiểu 01 ngày trước khi tiến hành xóa dữ
                liệu.
              </li>
            </ul>
            <br />
            <p>
              <b>4. THÔNG TIN CUNG CẤP</b>
            </p>
            <ul>
              <li>
                SSC không chia sẻ thông tin của Đối tác với các công ty, tổ
                chức, và cá nhân ngoài SSC trừ khi một trong những điều sau đây
                được áp dụng:
              </li>
              <li>
                SSC sử dụng thông tin từ tất cả các nguồn nhưng không giới hạn
                của đối tác, và các nguồn thông tin liên quan nhằm phát triển
                các tính năng phục vụ nhu cầu vận hành và truy xuất dữ liệu từ
                SSC.
              </li>
              <li>
                Với sự đồng ý của Đối tác, SSC sẽ chỉ chia sẻ thông tin cá nhân
                của Đối tác với các công ty, tổ chức hoặc cá nhân ngoài SSC khi
                SSC có sự đồng ý cụ thể của Đối tác để làm như vậy. Đối tác cá
                nhân khác, hoặc các doanh nghiệp Đối tác liên kết, hoặc dịch vụ
                bên thứ ba liên quan đến dịch vụ và phần mềm mà SSC cung cấp cho
                Đối tác.
              </li>
              <li>
                Vì lý do pháp lý: Trong trường hợp luật pháp áp dụng hoặc cho
                phép, SSC sẽ chia sẻ thông tin cá nhân với các công ty, tổ chức
                hoặc cá nhân không liên quan đến SSC nếu SSC tin rằng việc truy
                cập, sử dụng, lưu trữ hoặc tiết lộ thông tin đó là cần thiết.
              </li>
              <li>
                SSC sẽ tiếp tục đảm bảo bí mật về bất kỳ thông tin cá nhân nào
                nếu SSC sát nhập, mua hoặc bán với một bên khác, thông tin Đối
                tác vẫn được đảm bảo bí mật trước khi bàn giao hoặc tuân thủ
                theo chính sách bảo mật khác.
              </li>
            </ul>
            <br />
            <p><b>5. BẢO MẬT THÔNG TIN</b></p>
            <p>
              SSC làm việc chăm chỉ để bảo vệ SSC và Đối tác tránh việc bị truy
              cập trái phép, hoặc thay đổi, tiết lộ trái phép thông tin cá nhân
              mà SSC lưu giữ. Áp dụng những biện pháp công nghệ phù hợp để bảo
              vệ thông tin khách hàng, đơn hàng của Đối tác khi lưu trữ trên máy
              chủ của SSC.
            </p>

            <p><b>6. TIẾP NHẬN VÀ HƯỚNG DẪN ĐỐI TÁC DỊCH VỤ</b></p>
            <p>
              Hotline: (+84) 76.999.5555 của SSC sẽ hỗ trợ Đối tác sớm nhất với các
              yêu cầu cấp thiết. SSC sẽ không giải quyết bất kỳ vấn đề nào mà
              Đối tác yêu cầu từ dịch vụ bên ngoài.
            </p>

            <p><b>7. PHẠM VI ÁP DỤNG</b></p>
            <p>
              Chính sách bảo mật của SSC áp dụng cho tất cả các dịch vụ và phần
              mềm được cung cấp bởi SSC.
            </p>

            <p><b>8. THIẾT LẬP</b></p>
            <p>
              Chính sách bảo mật của SSC có thể thay đổi theo thời gian. SSC sẽ
              không thực hiện bất kỳ thay đổi quan trọng nào đối với chính sách
              này, bao gồm bất kỳ thay đổi nào có liên quan đến việc sử dụng
              thông tin Đối tác cá nhân nếu không có sự đồng ý của Đối tác.
            </p>

            <p><b>9. THAY ĐỔI ĐIỀU KHOẢN SỬ DỤNG</b></p>
            <p>
              SSC giữ quyền thay đổi mà không cần báo trước bất kỳ điều khoản
              nào trong Điều khoản Sử dụng này. Những sự thay đổi như vậy có
              hiệu lực ngay khi được cập nhật lên website SSC.ECO.
              <br />
              Đối tác tiếp tục sử dụng Phần mềm và Dịch vụ của SSC sau khi các
              thay đổi được đăng lên có nghĩa là Đối tác đã chấp nhận và đồng ý
              với các thay đổi đó.
            </p>

            <p><b>10. TỪ CHỐI ĐẢM BẢO</b></p>
            <p>
              SSC hoạt động dựa trên dữ liệu được cung cấp từ nhiều nguồn dữ
              liệu bên ngoài SSC nên Phần mềm và Dịch vụ của SSC được cung cấp
              dựa trên nguyên tắc không bảo hành, trên cơ sở dịch vụ và tính
              năng sẵn có mà không có bất kỳ sự bảo đảm bằng bất kỳ hình thức
              nào dù được thể hiện hoặc ngụ ý như vậy. Phần mềm SSC không đảm
              bảo quá trình sự vận hành các dịch vụ sẽ không bị gián đoạn hoặc
              bị lỗi.
            </p>

            <p><b>11. ĐỒNG Ý</b></p>
            <p>
              SSC sẽ yêu cầu sự chấp thuận của Đối tác trước khi sử dụng thông
              tin cá nhân cho bất kỳ mục đích nào ngoài những gì được quy định
              tại Chính sách Bảo mật này trong phạm vi pháp luật cho phép. SSC
              sẽ ngừng sử dụng bất kỳ thông tin cá nhân của Đối tác cho bất kỳ
              mục đích nào nếu Đối tác yêu cầu SSC làm như vậy.
            </p>
          </div>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Service",
  data: () => ({
    drawer: false,
    activeItem: "LayoutMain",
  }),
};
</script>
